:root {
  --duck-green: #19451C;
  --duck-brown: #76514A;
  --duck-accent: #8E6F69;
  --bill-color: #F7C100;
  --background-color: #333642;
  --water-color: transparent;
}

.box-canvas{
  position: relative;
  width: 600px;
  height:100px;
  display: block;
}

.head {
  position: absolute;
  left: 100px;
  background: var(--duck-green);
  width: 95px;
  height: 85px;
  border-radius: 50%;
}

/* Neck */
.head::before {
  content: '';
  position: absolute;
  bottom: -25px;
  right: 0;
  width: 50px;
  height: 60px;
  background: var(--duck-green);
  border-radius: 0 0 30% 30%;
  border-bottom: 10px solid white;
}

/* Eye */
.head::after {
  content: '';
  position: absolute;
  left: 30px;
  top: 25px;
  width: 15px;
  height: 15px;
  background: black;
  border-radius: 50%;
}

.bill {
  position: absolute;
  left: 58px;
  top: 50px;
  background: var(--bill-color);
  height: 12px;
  width: 50px;
  border-radius: 20% 0 20% 20%;
}

.bill::before {
  content: '';
  position: absolute;
  right: 0;
  top: -15px;
  background: var(--bill-color);
  height: 15px;
  width: 25px;
  clip-path: polygon(75% 0, 0 100%, 100% 100%);
}

.body {
  position: absolute;
  left: 130px;
  top: 85px;
  width: 270px;
  height: 150px;
  background: var(--duck-brown);
  border-radius: 30% 50%;
}

.body::after {
  content: '';
  position: absolute;
  right: 5px;
  top: 20px;
  width: 200px;
  height: 120px;
  background: var(--duck-accent);
  border-radius: 30% 50% 50% 30%;
  transform: rotate(10deg);
}

.tail {
  position: absolute;
  background: var(--duck-accent);
  width: 150px;
  height: 100px;
  clip-path: circle(50% at 50% 100%);
  transform: rotate(135deg);
  right: 120px;
  top: 130px;
}

.pond {
  position: absolute;
  top: 20px;
  left: -100px;
  width: 400px;
  height: 300px;
  background: var(--water-color);
  border-radius: 40%;
}

.pond::before {
  content: '';
  position: absolute;
  left: 170px;
  top: -100px;
  width: 500px;
  height: 370px;
  background: var(--water-color);
  border-radius: 40%;
}

.water {
  position: absolute;
  background: var(--water-color);
  width: 450px;
  height: 100px;
  top: 170px;
}