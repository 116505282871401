.swal2-close:focus {
	outline: none;
}

@media (max-width: 480px) {
  #parser {
    height: calc(100vh - 56px) !important;
  }
}

#parser {
	#editor {
		background: #1e1e1e;
	}

	#editor > div {
		height: 100%;
	}

	.monaco-editor.rename-box {
		bottom: 0;
	}

	#editor div[data-mode-id="Skript"] > .monaco-editor {
		height: 100% !important;
		width: 100% !important;
		.overflow-guard {
			height: 100% !important;
			width: 100% !important;
			.monaco-scrollable-element.editor-scrollable {
				width: calc(100% - 64px) !important;
			}
		}
	}

	#editor .monaco-editor .overflow-guard .margin-view-overlays {
		background: #111;
	}

	#editor .monaco-editor > .overflow-guard > .minimap.slider-mouseover {
		left: auto !important;
		right: 14px !important;
	}

	#files {
		background: #1e1e1e
	}

	#files > div {
		border-color: transparent;
		transition: border-color .2s;
		background: black;
		&:hover {
			border-color: white;
		}

	}

	#files > .active {
		background: #0f0f0f;
		border-color: rgb(242, 48, 48);
		&:hover {
			border-color: rgb(242, 48, 48);
		}
	}

	#files span {
		&::selection{
			background: white;
		}
		&:focus {
	  		outline: none;
	  	}
	}

	#filebar ul.absolute, #mobileFilebar ul.absolute {
		display: none;
		&.active {
			display: block;
		}
	}
}

.myInlineDecoration {
	background: red;
}