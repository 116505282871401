.searchAutocomplete {
	position: relative;

	&:hover, &.active {
		background-color: #e2e8f0;
	}

	&::after {
		display: none;
		font-weight: 600;
		position: absolute;
		color: #718096;
		right: 0;
		content: attr(content);
		font-size: 1.4rem;
		line-height: 1.4rem;
		padding-right: 15px;
	}
	&:hover::after, &.active::after {
		display: initial;
	}
}

.searchTag {
	position: relative;

	&:hover {
		color: rgba(0, 0, 0, .25)
	}

	&::after {
		color: black;
		content: "";
		text-align: center;
		position: absolute;
		width: 100%;
		left: 0;
		transition: .5s;
	}

	&:hover::after {
		content: attr(hover);
		background-color: rgba(226, 232, 240, 1);
	}
}