.fluentProgressBar-waiting {
	background: rgba(0,120,212,0);
	background: -moz-linear-gradient(left, rgba(0,120,212,0) 0%, rgba(0,120,212,1) 51%, rgba(0,120,212,0) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,120,212,0)), color-stop(51%, rgba(0,120,212,1)), color-stop(100%, rgba(0,120,212,0)));
	background: -webkit-linear-gradient(left, rgba(0,120,212,0) 0%, rgba(0,120,212,1) 51%, rgba(0,120,212,0) 100%);
	background: -o-linear-gradient(left, rgba(0,120,212,0) 0%, rgba(0,120,212,1) 51%, rgba(0,120,212,0) 100%);
	background: -ms-linear-gradient(left, rgba(0,120,212,0) 0%, rgba(0,120,212,1) 51%, rgba(0,120,212,0) 100%);
	background: linear-gradient(to right, rgba(0,120,212,0) 0%, rgba(0,120,212,1) 51%, rgba(0,120,212,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0078d4', endColorstr='#0078d4', GradientType=1 );
	
  	height: 4px;
  
    -webkit-animation: progressBarAnimation 2s linear infinite;
	animation: progressBarAnimation 2s linear infinite;
}

@keyframes progressBarAnimation {
	0% {
		left: -50%;
	}
	100% {
		left: 100%;
	}
}

.font-consolas {
	font-family: Consolas, "Courier New", monospace;
}

.lazyload-placeholder {
	height: 0px !important;
}


* {
	&::-webkit-scrollbar {
    width: 10px;
		background-color: #F5F5F5;
	}
	&::-webkit-scrollbar-track {
		background-color: rgb(13, 15, 16);
	}
	&::-webkit-scrollbar-thumb {
	  background-color: rgba(255, 255, 255, .7);
	}
}