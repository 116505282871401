#home {
  min-height: 100vh;
}

@media (min-width: 1920px) {
	#whyus.container {
	    max-width: 1440px;
	}
}

#navLogo {
	background: linear-gradient(to right, rgb(242, 48, 48), rgb(242, 48, 48) 50%, white 50%);
	background-position: -70px 0;
	background-size: 200px;
	-moz-background-clip: text;
	-o-background-clip: text;
	background-clip: text;
	-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: .5s all;
}

#navLogo:hover {
	background-position: 30px 0;
}